import { Autocomplete, TextField } from "@mui/material";
import { FieldProps } from "formik";
import React, { FC } from "react";

type PropType = {
  placeholder?: string;
  className?: string;
  multiline?: boolean;
  minRows?: number;
  disableAutocomplete?: boolean;
  /** Can be set to signal that this is a new password. So the browser gets the chance to save it */
  newPassword?: boolean;
};

const TextFormikField: FC<PropType & FieldProps> = ({
  placeholder,
  field,
  form: { touched, errors, handleChange, setTouched },
  className,
  multiline,
  minRows,
  disableAutocomplete,
  newPassword,
  ...props
}: PropType & FieldProps) => {
  const onBlur = () => setTouched({ ...touched, [field.name]: true });

  // Determine how to handle autocomplete. It could either
  //  - disable autocomplete if it's set
  //  - disable autocomplete but mark the field as "new-password"
  const inputProps =
    disableAutocomplete || newPassword
      ? {
          autoComplete: newPassword ? "new-password" : "off",
          form: {
            autoComplete: "off",
          },
        }
      : undefined;

  return (
    <TextField
      name={field.name}
      placeholder={placeholder}
      value={field.value}
      className={className}
      onChange={handleChange}
      multiline={multiline}
      minRows={minRows}
      {...props}
      inputProps={inputProps}
      onBlur={() => setTouched({ ...touched, [field.name]: true })}
      helperText={touched[field.name] && errors[field.name] && errors[field.name]}
      error={touched[field.name] && Boolean(errors[field.name])}
    />
  );
};

export default TextFormikField;
